import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

export const useCompanyBankingCreationDate = () => {
  const [creationDate, setCreationDate] = useState(undefined);
  const {companyDetails, wireDetails} = useSelector(state => ({
    companyDetails: state.banking?.companyDetails,
    wireDetails: state.transaction?.wireDetails,
  }));

  useEffect(() => {
    let date = wireDetails?.creationDate || companyDetails?.last_kyc_update;
    if (date !== creationDate) setCreationDate(date);
  }, [wireDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return creationDate;
}