import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledWelcomeModal,
  StyledWelcomeModalButton,
  StyledWelcomeModalSpace
} from './StyledWelcomeModal';
import {ReactComponent as InvitedSvgImage} from '../../../static/images/auth/invited.svg';

const WelcomeModal = ({
  enabledCompanyCreation,
  open,
  handleOk,
  ...rest
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'auth']);

  const t = (key, options) => trans(`welcome.${key}`, options);

  return (
    <StyledWelcomeModal
      centered={true}
      closeIcon={false}
      header={null}
      footer={null}
      open={open}
      width={408}
      wrapClassName='blur-bg'
      {...rest}
    >
      <StyledWelcomeModalSpace
        direction='vertical'
        size='large'
      >
        <InvitedSvgImage />
        <StyledWelcomeModalSpace
          direction='vertical'
          size='middle'
        >
          <h1>{t('title')}</h1>
          {enabledCompanyCreation ? (
            <>
              <p>{t('letsStartCompanySetup1')}</p>
              <StyledWelcomeModalButton
                onClick={handleOk}
                type='primary'
                size='large'
              >
                {t('getStarted')}
              </StyledWelcomeModalButton>
              <p>{t('letsStartCompanySetup2')}</p>
            </>
          ) : <p>{t('waitInvitation')}</p>}
        </StyledWelcomeModalSpace>
      </StyledWelcomeModalSpace>
    </StyledWelcomeModal>
  );
}

WelcomeModal.propTypes = {
  enabledCompanyCreation: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleOk: PropTypes.func
}

WelcomeModal.defaultProps = {
  enabledCompanyCreation: true
}

export default WelcomeModal;
