import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledInvoicesPageContainer} from './StyledInvoicesPage';
import SpinSmall from '../../components/SpinSmall';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import InvoiceTabs from '../../components/pages/InvoicesPage/InvoiceTabs';
import InvoiceForward from '../../components/pages/InvoicesPage/InvoiceForward';
import PageDocumentDetails from '../../components/PageDocumentDetails/PageDocumentDetails';
import {companyActions, invoicesActions} from '../../state/actions';
import {objectHelpers} from '../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const InvoicesPage = ({
  getEmployees,
  getInvoicesTotal,
}) => {
  const [t] = useTranslation(['invoices', 'main']);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => getEmployees(), [getEmployees]);

  useEffect(() => {
    handleUpdateTotal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const finishLoading = () => loading && setLoading(false);

  const handleUpdateTotal = () => {
    getInvoicesTotal(
      (data) => {
        setTotal({
          all: gObjProp(data, 'total_count', 0),
          matched: gObjProp(data, 'matched_count', 0),
          unmatched: gObjProp(data, 'unmatched_count', 0),
        });
        finishLoading();
      },
      finishLoading
    );
  }

  return (
    <StyledInvoicesPageContainer>
      <PageDocumentDetails title={t('main:pageTitles.invoicesList')} />
      <BalancePageHeader breadcrumbs={[{title: t('title')}]} />
      <InvoiceForward />
      <SpinSmall spinning={total === null && loading}>
        <InvoiceTabs
          handleUpdateTotal={handleUpdateTotal}
          total={total}
          totalLoading={loading}
        />
      </SpinSmall>
    </StyledInvoicesPageContainer>
  );
}

const mapDispatchToProps = {
  getInvoicesTotal: invoicesActions.getInvoicesTotal,
  getEmployees: companyActions.getEmployees,
}

export default connect(null, mapDispatchToProps)(InvoicesPage);
