import styled from 'styled-components';
import {typographyStyles} from '../../../../../utils/styles';

const StyledTabHelper = styled.div`
  .ant-btn-link {
    color: ${props => props.theme.colors.primary_6};
    padding: 0;
  }
`;

const StyledTabHelperDescription = styled.p`
  color: ${props => props.theme.colors.neutral_8};
  margin: 0;
  padding: 0;
  
  svg {
    height: 24px;
    width: 24px;
  }
`;

const StyledTabHelperTitle = styled.div`
  ${typographyStyles.heading.h5};
  color: ${props => props.theme.colors.neutral_10};
`;

export {
  StyledTabHelper,
  StyledTabHelperDescription,
  StyledTabHelperTitle
};
