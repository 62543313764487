import {bankingRequest} from '../services/request';
import {transactionConstants} from '../constants';
import {bankingEndpoints} from '../../api';
import {apiHelpers, objectHelpers} from '../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const {getUrl} = apiHelpers;

const errorHandlerProps = {disableAlert: true};

const getTransactionsList = ({
  headers,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: bankingEndpoints.GET_TRANSACTIONS,
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getUserTransactionsList = ({
  headers,
  userId,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_TRANSACTIONS, {userId}),
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getTransactionsTotals = ({
  headers,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: bankingEndpoints.GET_TRANSACTIONS_TOTALS,
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}


const downloadTransactions = ({
  headers,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: bankingEndpoints.GET_TRANSACTIONS_DOWNLOAD,
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const downloadUserTransactions = ({
  headers,
  query,
  userId,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_TRANSACTIONS_DOWNLOAD, {userId}),
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getSubscriptionTransactionsList = ({
  headers,
  id,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_SUBSCRIPTION_TRANSACTIONS, {id}),
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getUserCardTransactionsList = ({
  cardId,
  userId,
  headers,
  query,
  successCallback,
  errorCallback
}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_CARD_TRANSACTIONS, {cardId, userId}),
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const storeBalanceDetails = (balance, isLoaded = false) => {
  return (dispatch) => {
    dispatch({
      type: transactionConstants.SET_BALANCE_DETAILS,
      data: {
        currency: gObjProp(balance, 'currency', 'EUR'),
        value: gObjProp(balance, 'authorized_balance', 0),
        isLoaded,
        isRequested: true
      }
    });
  }
}

const storeWireDetails = (wallet, isLoaded = false) => {
  return (dispatch) => {
    dispatch({
      type: transactionConstants.SET_WIRE_DETAILS,
      data: {
        bic: gObjProp(wallet, 'bic'),
        creationDate: gObjProp(wallet, 'created_at'),
        iban: gObjProp(wallet, 'iban'),
        walletId: gObjProp(wallet, 'id'),
        isLoaded
      }
    });
  }
}

const storeBankingUserId = (id) => {
  return (dispatch) => {
    dispatch({
      type: transactionConstants.SET_COMPANY_BANKING_USER_ID,
      data: {id}
    });
  }
}

const openAddInvoiceModal = (transaction) => {
  return (dispatch) => {
    dispatch({
      type: transactionConstants.OPEN_ADD_INVOICE_MODAL,
      data: {
        open: true,
        transaction
      }
    });
  }
}

const closeAddInvoiceModal = () => {
  return (dispatch) => {
    dispatch({
      type: transactionConstants.CLOSE_ADD_INVOICE_MODAL
    });
  }
}

const clearBankingDetails = () => {
  return {
    type: transactionConstants.CLEAR_TRANSACTIONS_BANKING_DETAILS
  }
}


export const transactionActions = {
  clearBankingDetails,
  getTransactionsList,
  getTransactionsTotals,
  downloadTransactions,
  downloadUserTransactions,
  getSubscriptionTransactionsList,
  getUserCardTransactionsList,
  getUserTransactionsList,
  storeBalanceDetails,
  storeWireDetails,
  storeBankingUserId,

  openAddInvoiceModal,
  closeAddInvoiceModal
};
