import styled from 'styled-components';
import {Space} from 'antd';
import Search from '../../../Search';

const StyledTableFilters = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledTableFiltersSearch = styled(Search)`
  max-width: 264px;
  width: 100%;
`;

const StyledFilterElements = styled(Space)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledRightSideContent = styled.div`
`;

const StyledFilterElementsSpace = styled(Space)`
  & > .ant-space {
    &:last-of-type {
      margin-right: 8px;
    }
  }
  
  .ant-select-multiple.ant-select-lg {
    .ant-select-selector {
      min-width: 180px;
    }
  }
`;

export {
  StyledTableFiltersSearch,
  StyledTableFilters,
  StyledFilterElements,
  StyledFilterElementsSpace,
  StyledRightSideContent
};
