import {companyConstants} from '../constants';
import {request} from '../services/request';
import {helpers as stateHelpers} from '../services/helpers';
import {mainActions} from './main.actions';
import {backendEndpoints} from '../../api';
import {apiHelpers, objectHelpers, teamsHelpers, textHelpers} from '../../utils/helpers';

const errorHandlerProps = {disableAlert: true};

const getCompanyUrl = (url, getState, params = {}) => {
  const {id} = getState().company;
  return apiHelpers.getUrl(url, {id, ...params});
}

const getTags = () => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_TAGS, getState),
      successCallback: (data) => {
        dispatch({
          type: companyConstants.GET_TAGS,
          data
        });
      },
      errorCallback: () => {},
      errorHandlerProps
    });
  }
}

const getEmployees = () => {
  return (dispatch, getState) => {
    const {employee} = getState().user;
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_EMPLOYEES, getState),
      successCallback: (data) => {
        data.sort((a, b) => a.full_name.localeCompare(b.full_name));
        let employeeIndex = data.findIndex(i => i.email === employee.email);
        if (employeeIndex) data.unshift(data.splice(employeeIndex, 1)[0])
        dispatch({
          type: companyConstants.GET_EMPLOYEES,
          data
        });
      },
      errorCallback: () => {},
      errorHandlerProps
    });
  }
}

const getTeamEmployees = (query = null, nextPage = false) => {
  return (dispatch, getState) => {
    const {teamEmployees} = getState().company;
    const {searchQuery} = teamEmployees;
    let url = getCompanyUrl(backendEndpoints.GET_COMPANY_TEAM_EMPLOYEES, getState);
    let urlSearchQuery = query ? {...searchQuery, ...query} : {order_by: '-name'};
    url = stateHelpers.getUrl(url, teamEmployees, nextPage);
    request.get({
      dispatch,
      url,
      query: urlSearchQuery,
      successCallback: (data) => {
        const users = nextPage ? [...teamEmployees.users, ...data.users] : data.users;
        dispatch({
          type: companyConstants.GET_TEAM_EMPLOYEES,
          data: {
            users,
            pagination: data.pagination,
            searchQuery: urlSearchQuery
          }
        });
      },
      errorCallback: () => {},
      errorHandlerProps
    });
  }
}

const clearTeamEmployees = () => {
  return (dispatch) => {
    dispatch({
      type: companyConstants.CLEAR_TEAM_EMPLOYEES,
    });
  }
}

const getCompanySettings = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_SETTINGS, getState),
      successCallback: (data) => {
        dispatch({
          type: companyConstants.GET_SETTINGS,
          data
        });
        success && success(data);
      },
      errorCallback: (resp) => error && error(resp)
    });
  }
}

const getCompanyCurrentCurrency = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_CURRENT_CURRENCY, getState),
      successCallback: (data) => {
        dispatch({
          type: companyConstants.GET_COMPANY_CURRENT_CURRENCY,
          data: data.currency
        });
        if (success) success();
      },
      errorCallback: () => error && error()
    });
  }
}

const getCompanyInvoiceEmail = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_INVOICE_EMAIL, getState),
      successCallback: (data) => {
        dispatch({
          type: companyConstants.GET_COMPANY_INVOICE_EMAIL,
          data
        });
        success && success(data);
      },
      errorCallback: (resp) => error && error(resp)
    });
  }
}

const getCompanyInformation = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_DESCRIPTION, getState),
      successCallback: (response) => {
        const getProp = (propName) => objectHelpers.getObjProp(response, propName, '') || '';
        const data = {
          address: response.address,
          city: response.city,
          full_address: teamsHelpers.getCompanyAddress(response),
          country: textHelpers.getCapitalized(getProp('country')),
          zip_city: `${getProp('zip_code')} ${getProp('city')}`,
          zip_code: response.zip_code,
          vat: response.vat_id
        };
        dispatch({
          type: companyConstants.GET_COMPANY_INFORMATION,
          data
        });
        if (success) success(response);
      },
      errorCallback: () => error && error()
    });
  }
}

const updateCompanyInformation = (data, success, error) => {
  return (dispatch, getState) => {
    request.put({
      dispatch,
      url: getCompanyUrl(backendEndpoints.PUT_UPDATE_COMPANY_SETTING, getState),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (errors) => {
        let data = objectHelpers.getObjProp(errors.response, 'data');
        error && error(data);
      },
      errorHandlerProps
    });
  }
}

const addNewTag = (tagName, success, error) => {
  return (dispatch, getState) => {
    const data = {tag_name: tagName}

    request.post({
      dispatch,
      url: getCompanyUrl(backendEndpoints.POST_COMPANY_ADD_NEW_TAG, getState),
      data,
      successCallback: (data) => {
        dispatch({
          type: companyConstants.ADD_NEW_TAG,
          data
        });
        if (success) success();
      },
      errorCallback: () => error && error()
    });
  }
}

const deleteTag = (tagId, success, error) => {
  return (dispatch, getState) => {
    const {tags} = getState().company;
    request.delete({
      dispatch,
      url: getCompanyUrl(backendEndpoints.DELETE_COMPANY_TAG, getState, {tagId}),
      successCallback: () => {
        dispatch({
          type: companyConstants.DELETE_TAG,
          data: tags.filter(tag => tag.tag_id !== tagId)
        });
        if (success) success();
      },
      errorCallback: () => error && error()
    });
  }
}


const companyRegistration = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_COMPANY_REGISTRATION,
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp),
      errorHandlerProps
    });
  }
}

const sendEmailVerification = (email, success, error) => {
  return verificationRequestTemplate(
    {email},
    backendEndpoints.PUT_START_COMPANY_EMAIL_VERIFICATION,
    'SEND_COMPANY_EMAIL_VERIFICATION',
    success,
    error,
    false
  );
}

const finishEmailVerification = (token, success, error) => {
  return verificationRequestTemplate(
    {token},
    backendEndpoints.PUT_FINISH_COMPANY_EMAIL_VERIFICATION,
    'FINISH_COMPANY_EMAIL_VERIFICATION',
    success,
    error,
    false,
    errorHandlerProps
  );
}

const verificationRequestTemplate = (
  data,
  url,
  dispatchTypeName,
  success,
  error,
  showLoader = true,
  errorHandlerProps = {}
) => {
  return (dispatch, getState) => {
    const loading = (loadingStatus) => showLoader && mainActions.setLoading(loadingStatus, dispatch);

    loading(true);
    request.put({
      dispatch,
      url: getCompanyUrl(url, getState),
      data,
      successCallback: (data) => {
        dispatch({
          type: companyConstants[`SUCCESS_${dispatchTypeName}`],
          data
        });
        loading(false);
        success && success(data);
      },
      errorCallback: (err) => {
        const {data} = err.response;
        error && error(data);
        loading(false);
        dispatch({
          type: companyConstants[`ERROR_${dispatchTypeName}`],
          data
        });
      },
      errorHandlerProps
    });
  }
}

const setCompanyLegalRepresentative = (email, success, error) => {
  return (dispatch, getState) => {
    const data = {legal_representative: email};

    request.put({
      dispatch,
      url: getCompanyUrl(backendEndpoints.PUT_SET_COMPANY_LEGAL_REPRESENTATIVE, getState),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const getCompanyLegalRepresentative = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getCompanyUrl(backendEndpoints.GET_COMPANY_LEGAL_REPRESENTATIVE, getState),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

export const companyActions = {
  addNewTag,
  deleteTag,
  getCompanySettings,
  getEmployees,
  getTags,
  getTeamEmployees,
  updateCompanyInformation,
  getCompanyInformation,
  getCompanyInvoiceEmail,
  getCompanyCurrentCurrency,

  companyRegistration,

  sendEmailVerification,
  finishEmailVerification,

  setCompanyLegalRepresentative,
  getCompanyLegalRepresentative,

  clearTeamEmployees
};
