import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {StyledHeaderDetails} from './StyledHeaderDetails';
import HeaderInformation from '../../../HeaderInformation';
import StatusLine from '../../SubscriptionsPage/StatusLine';
import AddButton from '../../SubscriptionsPage/AddButton';
import {userRolesConstants, userStatusesConstants} from '../../../../constants';
import {objectHelpers, teamsHelpers} from '../../../../utils/helpers';
import {colorsStyles} from '../../../../utils/styles';

const {ACTIVE, DISABLED, EXTERNAL, PENDING} = userStatusesConstants;
const {ADMIN, SUPPORT, USER} = userRolesConstants;

const HeaderDetails = ({
  dispatch,
  data,
  enableActions,
  onActionClick,
  onRoleChange,

  isSupport,

  ...rest
}) => {
  const [t] = useTranslation(['main', 'teams']);
  const [roleBtnProps, setRoleBtnProps] = useState({label: '', loading: false});

  const gObjProp = (key, defaultValue = '') => objectHelpers.getObjProp(data, key, defaultValue);

  const logo = gObjProp('logo') || undefined;
  const email = gObjProp('email');
  const fullName = gObjProp('full_name');
  const role = gObjProp('role');
  const status = gObjProp('account_status');
  const userRole = teamsHelpers.getUserRole(role);
  const isSupportRole = role === SUPPORT;

  const buttonMenuItems = [
    {key: ADMIN, label: t('userRoles.admin')},
    {key: USER, label: t('userRoles.user')}
  ].map(role => ({...role, disabled: role.label === roleBtnProps.label}));

  useEffect(() => {
    if (data) {
      const role = objectHelpers.getObjProp(data, 'role');
      const roleLabel = getRoleLabel(role);
      setRoleBtnProps({...roleBtnProps, label: roleLabel});
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoleLabel = (role) => {
    const roleOption = buttonMenuItems.find(o => o.key === role);
    return roleOption?.label || '';
  }

  const actionButtons = {
    [ACTIVE]: !isSupport && (
      <Button
        danger
        onClick={(e) => onActionClick({e, action: 'disable'})}
        size='large'
        type='primary'
      >
        {t('disable')}
      </Button>
    ),
    [DISABLED]: !isSupport && (
      <Button
        onClick={(e) => onActionClick({e, action: 'enable'})}
        size='large'
        type='primary'
      >
        {t('enable')}
      </Button>
    ),
    [EXTERNAL]: (
      <Button
        onClick={(e) => onActionClick({e, action: 'invite'})}
        size='large'
        type='primary'
      >
        {t('invite')}
      </Button>
    ),
    [PENDING]: (
      <>
        <Button
          onClick={(e) => onActionClick({e, action: 'resend-invite'})}
          size='large'
          type='primary'
        >
          {t('teams:resendInvite')}
        </Button>
        {!isSupport && (
          <Button
            danger
            onClick={(e) => onActionClick({e, action: 'delete'})}
            size='large'
            type='primary'
          >
            {t('cancel')} {t('invitation')}
          </Button>
        )}
      </>
    ),
  };

  const handleOnRoleChange = ({key}) => {
    if (onRoleChange) {
      const role = parseInt(key);
      const roleLabel = getRoleLabel(role);
      const prevRoleLabel = roleBtnProps.label;
      setRoleBtnProps({...roleBtnProps, loading: true})
      onRoleChange(
        role,
        () => setRoleBtnProps({...roleBtnProps, label: roleLabel, loading: false}),
        () => setRoleBtnProps({...roleBtnProps, label: prevRoleLabel, loading: false})
      );
    }
  }

  const getActionButtons = () => {
    let buttons = actionButtons.hasOwnProperty(status) ? actionButtons[status] : '';
    // hide actions is editor has support role && edited user has support role as well
    if (isSupportRole && !isSupport && [ACTIVE, DISABLED].includes(status)) {
      buttons = '';
    }
    return buttons;
  }

  const extra = (
    <Space size='small'>
      <StatusLine color={status === DISABLED ? colorsStyles.functional_danger_d : userRole.color} /> {t(userRole.key)}
    </Space>
  );

  const buttons = getActionButtons();

  return (
    <StyledHeaderDetails
      {...rest}
    >
      <HeaderInformation
        description={email}
        extra={extra}
        logo={logo}
        name={fullName}
      />
      {enableActions && (
        <Space size='middle'>
          {!isSupportRole && (
            <AddButton
              disabled={isSupport}
              loading={roleBtnProps.loading}
              menuItems={buttonMenuItems}
              onClick={handleOnRoleChange}
              size='large'
            >
              {roleBtnProps.label}
            </AddButton>
          )}
          {buttons}
        </Space>
      )}
    </StyledHeaderDetails>
  );
}

HeaderDetails.propTypes = {
  data: PropTypes.object,
  enableActions: PropTypes.bool,
  onActionClick: PropTypes.func,
  onRoleChange: PropTypes.func
}

HeaderDetails.defaultProps = {
  enableActions: true
}

const mapStateToProps = state => {
  const {isSupport} = state.user;

  return {
    isSupport
  }
}

export default connect(mapStateToProps, null)(HeaderDetails);
