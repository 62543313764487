import {Routes as DomRoutes, Route as DomRoute, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import CardPage from '../pages/CardPage';
import CardsPage from '../pages/CardsPage';
import OverviewPage from '../pages/OverviewPage';
import OverviewUserPage from '../pages/OverviewUserPage';
import TeamPage from '../pages/TeamPage';
import TeamUserPage from '../pages/TeamUserPage';
import SettingsPage from '../pages/SettingsPage';
import ProfileSettingsPage from '../pages/ProfileSettingsPage';
import SignUpPage from '../pages/SignUpPage';
import LoginPage from '../pages/LoginPage';
import ForgotPinPage from '../pages/ForgotPinPage';
import TransactionsPage from '../pages/TransactionsPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import CompanyInvitePage from '../pages/CompanyInvitePage';
import EmailVerificationPage from '../pages/EmailVerificationPage';
import NotFoundPage from '../pages/NotFoundPage';
import VerifySuccessPage from '../pages/VerifySuccessPage';
import UserInvitePage from '../pages/UserInvitePage';
import OAuthCallbackPage from '../pages/OAuthCallbackPage';
import QuickGoogleConnectPage from '../pages/QuickGoogleConnectPage';
import InvoicesPage from '../pages/InvoicesPage';
import SupportPage from '../pages/SupportPage';
import routes from './routes.json';
import {availableModulesConstants} from '../constants';
import {StyledSpin} from '../app/StyledApp';
import {objectHelpers, systemHelpers} from '../utils/helpers';

const {CARDS, TRANSACTIONS, SUBSCRIPTIONS} = availableModulesConstants;

const isAvailableSubscriptionsModule = systemHelpers.checkIfAvailableFeature(SUBSCRIPTIONS);
const isAvailableTransactionsModule = systemHelpers.checkIfAvailableFeature(TRANSACTIONS);
const isRequiredCheckPath = isAvailableSubscriptionsModule || isAvailableTransactionsModule;

const bankingRoutes = ['cardDetails', 'cardsList', 'invoicesList', 'subscriptionsList', 'subscriptionDetails',
  'transactionsList'].map(key => routes[key]);

const Routes = ({
  availableModules,
  companyState,
  isAdmin,
  isExistsBankingUser,
  isAuth,
}) => {
  const location = useLocation();

  const Route = (key, element) => {
    if (routes.hasOwnProperty(key)) {
      return (
        <DomRoute
          key={key}
          exact
          path={routes[key]}
          element={element}
        />
      )
    } else {
      return null;
    }
  };

  const getAdminRoutes = () => {
    return [
      Route('settings', <SettingsPage />),
      Route('team', <TeamPage />),
      Route('teamUserDetails', <TeamUserPage />),
    ];
  }

  const validateBankingRoute = () => {
    const pathname = location.pathname;
    const splitPathname = pathname.split('/');
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const uuidSimplifiedLength = 32;
    let isBankingRoute = false;

    for (const route of bankingRoutes) {
      // Direct match with the pathname
      if (route === pathname) {
        isBankingRoute = true;
        break;
      }

      const splitRoute = route.split('/');

      // Check if route and pathname have the same structure (length)
      if (splitRoute.length === splitPathname.length) {
        const adjustedPathname = [...splitPathname];
        let word;

        // Replace UUID placeholders with empty strings if matching
        splitRoute.forEach((segment, index) => {
          word = adjustedPathname[index];
          if (segment.startsWith(':') && (uuidPattern.test(word) || word.length === uuidSimplifiedLength)) {
            splitRoute[index] = '';
            adjustedPathname[index] = '';
          }
        });

        // Check if the modified arrays match
        if (objectHelpers.arraysIsEqual(splitRoute, adjustedPathname)) {
          isBankingRoute = true;
          break;
        }
      }
    }

    return isBankingRoute;
  };

  const getMainRoutes = () => {
    const isBankingRoute = validateBankingRoute();
    return [
      <DomRoute
        element={<CompanyInvitePage isAuth={isAuth} />}
        key='invite'
        exact
        path={routes.invite}
      />,
      Route('verification', <EmailVerificationPage />),
      Route('verifyIdentity', <VerifySuccessPage />),
      Route('userInvite', <UserInvitePage />),
      Route('oauthCallback', <OAuthCallbackPage />),
      Route('quickGoogleConnect', <QuickGoogleConnectPage />),
      <DomRoute
        // Display loaded while company state is defined if current route is banking
        element={
          isRequiredCheckPath && isBankingRoute
            ? companyState && availableModules?.loaded === true
              ? <NotFoundPage /> : (
                <StyledSpin
                  spinning={true}
                  wrapperClassName='full-width-spin'
                />
              )
            : <NotFoundPage />
        }
        path='*'
        key='not-found'
      />
    ];
  }

  const adminRoutes = getAdminRoutes();

  const mainRoutes = getMainRoutes();

  if (!isAuth) {
    return (
      <DomRoutes>
        {Route('signUp', <SignUpPage />)}
        {Route('login', <LoginPage />)}
        {Route('forgotPin', <ForgotPinPage />)}
        {mainRoutes}
      </DomRoutes>
    )
  }

  const getAppRoutes = () => {
    let appRoutes = [
      Route('overview', isAdmin ? <OverviewPage /> : <OverviewUserPage />),
      Route('profile', <ProfileSettingsPage />),
      Route('support', <SupportPage />),
    ];
    const modules = availableModules?.modules || [];
    const isAvailableCards = modules.includes(CARDS);
    const isAvailableSubscriptions = modules.includes(SUBSCRIPTIONS);
    const isAvailableTransactions = modules.includes(TRANSACTIONS);

    if (isAdmin) appRoutes = [...appRoutes, ...adminRoutes];

    if (isAvailableCards) {
      appRoutes = [ ...appRoutes, Route('cardDetails', <CardPage />) ];
      if (isAdmin) appRoutes = [ ...appRoutes, Route('cardsList', <CardsPage />) ];
    }
    if (isAvailableSubscriptions) {
      appRoutes = [ ...appRoutes, Route('subscriptionDetails', <SubscriptionPage />) ];
      if (isAdmin) appRoutes = [ ...appRoutes, Route('subscriptionsList', <SubscriptionsPage />) ];
    }
    if (isAvailableTransactions) {
      if (isAdmin) {
        appRoutes = [
          ...appRoutes,
          Route('transactionsList', <TransactionsPage />),
          Route('invoicesList', <InvoicesPage />)
        ];
      }
      if (!isAdmin && isExistsBankingUser) {
        appRoutes = [
          ...appRoutes,
          Route('transactionsList', <TransactionsPage />),
        ];
      }
    }

    appRoutes = [...appRoutes, ...mainRoutes];

    return appRoutes;
  }

  const appRoutes = getAppRoutes();

  return (
    <DomRoutes>
      {appRoutes}
    </DomRoutes>
  );
}

const mapStateToProps = state => {
  const {banking, user} = state;
  const {isAdmin} = user;

  return {
    availableModules: state?.main?.availableModules,
    isAdmin,
    isExistsBankingUser: banking?.verification?.isCreatedUser,
    companyState: banking?.companyState
  }
}

Routes.propTypes = {
  isAuth: PropTypes.bool
}

Routes.defaultProps = {
  isAuth: false
}

export default connect(mapStateToProps, null)(Routes);
