import styled from 'styled-components';
import {bordersStyles} from '../../utils/styles';

const StyledTransactionsPageContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  overflow: hidden;
  transition: .2s ease;
  
  &.open-details {
    padding-right: 0;
  }
  
  .transaction-details-panel {
    & > div {
      padding-left: 24px;
    }
  }
  
  .transaction-details-panel-content {
    margin-left: 0;
  }
`;

const StyledTransactionsPageContent = styled.div`
  ${bordersStyles.light};
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px 24px 54px 24px;
  min-height: calc(100vh - 80px - 64px);
  overflow-x: auto;
  
  &.with-invoice-forward {
    min-height: calc(100vh - 80px - 70px - 64px);
  }
`;

export {
  StyledTransactionsPageContainer,
  StyledTransactionsPageContent
};
