import {request} from '../services/request';
import {expenseConstants} from '../constants';
import {helpers as stateHelpers} from '../services/helpers';
import {backendEndpoints} from '../../api';
import {apiHelpers, dateHelpers, objectHelpers} from '../../utils/helpers';

const errorHandlerProps = {disableAlert: true};

const {getUrl} = apiHelpers;

const getExpense = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_EXPENSE, {id}),
      successCallback: (data) => {
        dispatch({
          type: expenseConstants.GET_EXPENSE,
          data
        });
        success && success();
      },
      errorCallback: () => error && error()
    });
  }
}

const getExpenseAttachment = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_EXPENSE_ATTACHMENT, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: () => error && error(),
      errorHandlerProps
    });
  }
}

const updateExpense = (id, data, propName, success, error) => {
  return (dispatch, getState) => {
    let expensesList = getState().expense.expenses;
    let {expenses} = expensesList;
    const method = propName === 'notes' ? 'post' : 'put';
    const methodName = `PUT_EXPENSE_UPDATE_${propName.toUpperCase()}`;
    request[method]({
      dispatch,
      url: getUrl(backendEndpoints[methodName], {id}),
      data,
      successCallback: (data) => {
        // update expense in expenses list
        expenses = expenses.map(expense => {
          if (expense.id === data.id) {
            expense = {
              ...expense,
              category: data.category,
              note: data.notes ? objectHelpers.getObjProp(data.notes, 'elements', []).length > 0 : false,
              tags: data.tags.length > 0
            }
          }
          return expense
        });

        dispatch({
          type: expenseConstants.UPDATE_EXPENSE,
          data,
          expenses: {
            ...expensesList,
            expenses
          }
        });
        success && success(data);
      },
      errorCallback: () => error && error()
    });
  }
}

const updateExpenseAttachmentFile = (id, data, successFunc, errorFunc) => {
  return (dispatch) => {
    request.patch({
      dispatch,
      url: getUrl(backendEndpoints.PATCH_EXPENSE_UPDATE_ATTACHMENT, {id}),
      data: {
        attachment_file: data.file,
        file_name: data.name
      },
      successCallback: (data) => {
        dispatch({
          type: expenseConstants.UPDATE_EXPENSE_ATTACHMENT_FILE,
          data
        });
        successFunc && successFunc();
      },
      errorCallback: (err) => errorFunc && errorFunc(err)
    });
  }
}

const exportExpenses = (success, error) => {
  return (dispatch, getState) => {
    const {query, expenses} = getState().expense;
    const searchQuery = {...query, ...expenses.searchQuery}

    request.get({
      dispatch,
      url: backendEndpoints.GET_EXPENSES_EXPORT,
      query: searchQuery,
      successCallback: (data) => {
        const filename = `expenses-${dateHelpers.getDate(new Date(), 'M_D_YYYY')}`
        stateHelpers.handleCSVExport(data, filename);
        dispatch({type: expenseConstants.EXPORT_EXPENSES});
        success && success();
      },
      errorCallback: (err) => error && error(err)
    });
  }
}

const getTransactionExpense = (cardId, transactionId, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_TRANSACTION_EXPENSE, {cardId, transactionId}),
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const getBatchExpenses = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_EXPENSES_BATCH,
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const downloadZipInvoices = (query, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_EXPENSES_ZIP_INVOICES,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err)
    });
  }
}

export const expenseActions = {
  getExpense,
  getExpenseAttachment,
  getTransactionExpense,
  getBatchExpenses,

  exportExpenses,

  updateExpense,
  updateExpenseAttachmentFile,
  downloadZipInvoices
};
