export const companyConstants = {
  ADD_NEW_TAG: 'ADD_NEW_TAG',
  CLEAR_COMPANY_DATA: 'CLEAR_COMPANY_DATA',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  DELETE_TAG: 'DELETE_TAG',
  GET_COMPANY_INFORMATION: 'GET_COMPANY_INFORMATION',
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  GET_EMPLOYEES: 'GET_EMPLOYEES',
  GET_TEAM_EMPLOYEES: 'GET_TEAM_EMPLOYEES',
  CLEAR_TEAM_EMPLOYEES: 'CLEAR_TEAM_EMPLOYEES',
  GET_TAGS: 'GET_TAGS',
  GET_SETTINGS: 'GET_SETTINGS',
  RESEND_INVITE: 'RESEND_INVITE',
  SET_COMPANY: 'SET_COMPANY',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_STATUS: 'UPDATE_EMPLOYEE_STATUS',
  GET_COMPANY_CURRENT_CURRENCY: 'GET_COMPANY_CURRENT_CURRENCY',
  GET_COMPANY_INVOICE_EMAIL: 'GET_COMPANY_INVOICE_EMAIL',

  SUCCESS_SEND_COMPANY_EMAIL_VERIFICATION: 'SUCCESS_SEND_COMPANY_EMAIL_VERIFICATION',
  ERROR_SEND_COMPANY_EMAIL_VERIFICATION: 'ERROR_SEND_COMPANY_EMAIL_VERIFICATION',
  SUCCESS_FINISH_COMPANY_EMAIL_VERIFICATION: 'SUCCESS_FINISH_COMPANY_EMAIL_VERIFICATION',
  ERROR_FINISH_COMPANY_EMAIL_VERIFICATION: 'ERROR_FINISH_COMPANY_EMAIL_VERIFICATION'
};
