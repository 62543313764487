import styled from 'styled-components';
import TransactionDetailsPanel from '../../TransactionsPage/TransactionDetailsPanel';
import Empty from '../../../Empty';

const StyledInvoiceTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  padding: ${props => props.opened ? '24px 0px 24px 24px' : '24px 24px 24px 24px'};
  position: relative;
  transition: .3s ease-in-out;
  
  & > div {
    position: relative;
  }
`;

const StyledInvoiceDetailsPanel = styled(TransactionDetailsPanel)`
  & > div {
    margin-top: ${props => props.fixed ? '0' : '41px'};
    & > div {
      width: calc(100% - 24px);
    }
  }
`;

const StyledInvoiceTabsContent = styled.div`
  .ant-tabs-content-holder {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    min-height: unset;
  }
`;

const StyledInvoiceTabsEmpty = styled(Empty)`
  margin-top: 0;
  
  .ant-empty-description {
    & > div {
      max-width: 620px;
    }
  }
`;

export {
  StyledInvoiceTabsContainer,
  StyledInvoiceDetailsPanel,
  StyledInvoiceTabsContent,
  StyledInvoiceTabsEmpty
}
