export const bankingConstants = {
  CLEAR_CARDS: 'CLEAR_CARDS',
  CLEAR_CARDS_DATA: 'CLEAR_CARDS_DATA',
  CREATE_USER: 'CREATE_USER',
  GET_COMPANY_STATE: 'GET_COMPANY_STATE',
  GET_COMPANY_DETAILS: 'GET_COMPANY_DETAILS',
  GET_CARD_LIST: 'GET_CARD_LIST',
  GET_CARD_USERS: 'GET_CARD_USERS',
  GET_USER_CARD_DETAILS: 'GET_USER_CARD_DETAILS',
  GET_USER_CARD_LIST: 'GET_USER_CARD_LIST',
  UNBLOCK_PIN: 'UNBLOCK_PIN',
  SET_VERIFICATION_STATUS: 'SET_VERIFICATION_STATUS',
  SET_INVITED_USER: 'SET_INVITED_USER',
  SET_LEGAL_PERSON_DETAILS: 'SET_LEGAL_PERSON_DETAILS'
};
