import styled from 'styled-components';
import {Divider, Segmented, Select, Space} from 'antd';
import Search from '../../../Search';
import {typographyStyles} from '../../../../utils/styles';

const StyledTableFilters = styled(Space)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledTableFiltersCardSelect = styled(Select)`
  min-width: 190px;
  
  &.ant-select-lg {
    .ant-select-selector {
      &::after {
        line-height: 30px;
      }
    }
  }
      
  .ant-select-selection-item-content {
    & > .ant-space {
      & > .ant-space-item {
        &:nth-of-type(1) {
          display: none;
        }
      }
    }
  }
`;

const StyledTableFiltersDivider = styled(Divider)`
  height: 32px;
  top: 0;
`;

const StyledTableFiltersSearch = styled(Search)`
  max-width: 168px;
  width: 100%;
`;

const StyledTableFiltersSegmented = styled(Segmented)`
  background-color: #F3F3F4;
  padding: 4px;
  
  .ant-segmented-item {
    ${typographyStyles.heading.h5_1};
    font-weight: 500;
    
    &.ant-segmented-item-selected {
      color: ${props => props.theme.colors.neutral_10};
    }
  }
  .ant-segmented-item-label {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
    padding: 0 8px;
  }
`;

export {
  StyledTableFilters,
  StyledTableFiltersCardSelect,
  StyledTableFiltersDivider,
  StyledTableFiltersSearch,
  StyledTableFiltersSegmented
};
