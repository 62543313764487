import styled from 'styled-components';
import {Button} from 'antd';

const StyledAddButton = styled(Button)`
  .ant-space-item {
    display: flex;
  }
  &.ant-btn-primary,
  &.ant-btn-dangerous,
  &.green-btn {
    svg {
      path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  svg {
    transition: 0.3s ease;
    transform: ${props => props.open ? 'rotate(180deg)' : 'unset'};
    path {
      fill: rgba(0, 0, 0, 0.88);
      stroke: rgba(0, 0, 0, 0.88);
    }
  }
  
  &:disabled {
    svg {
      path {
        fill: ${props => props.theme.colors.neutral_6};
        stroke: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

export {
  StyledAddButton
}
