import React from 'react';

const AddInvoiceIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2976_18861)">
      <path
        d="M13.3333 10.7667H21.3333C21.687 10.7667 22.0261 10.6244 22.2761 10.3713C22.5262 10.1181 22.6667 9.77472 22.6667 9.41668C22.6667 9.05864 22.5262 8.71526 22.2761 8.46209C22.0261 8.20891 21.687 8.06668 21.3333 8.06668H13.3333C12.9797 8.06668 12.6406 8.20891 12.3905 8.46209C12.1405 8.71526 12 9.05864 12 9.41668C12 9.77472 12.1405 10.1181 12.3905 10.3713C12.6406 10.6244 12.9797 10.7667 13.3333 10.7667ZM10.6667 16.1667H13.3333C13.687 16.1667 14.0261 16.0244 14.2761 15.7713C14.5262 15.5181 14.6667 15.1747 14.6667 14.8167C14.6667 14.4586 14.5262 14.1153 14.2761 13.8621C14.0261 13.6089 13.687 13.4667 13.3333 13.4667H10.6667C10.313 13.4667 9.97391 13.6089 9.72386 13.8621C9.47381 14.1153 9.33333 14.4586 9.33333 14.8167C9.33333 15.1747 9.47381 15.5181 9.72386 15.7713C9.97391 16.0244 10.313 16.1667 10.6667 16.1667ZM10.6667 21.5667C11.0203 21.5667 11.3594 21.4244 11.6095 21.1713C11.8595 20.9181 12 20.5747 12 20.2167C12 19.8586 11.8595 19.5153 11.6095 19.2621C11.3594 19.0089 11.0203 18.8667 10.6667 18.8667C10.313 18.8667 9.97391 19.0089 9.72386 19.2621C9.47381 19.5153 9.33333 19.8586 9.33333 20.2167C9.33333 20.5747 9.47381 20.9181 9.72386 21.1713C9.97391 21.4244 10.313 21.5667 10.6667 21.5667Z"
        fill="#BFBFBF"/>
      <path
        d="M26.6667 2.66668H5.33333C4.97971 2.66668 4.64057 2.80891 4.39052 3.06209C4.14048 3.31526 4 3.65864 4 4.01668V28.3167C4 28.5674 4.06895 28.8131 4.19913 29.0264C4.32931 29.2397 4.51557 29.412 4.73705 29.5242C4.95852 29.6363 5.20646 29.6837 5.45307 29.6612C5.69969 29.6387 5.93524 29.5471 6.13333 29.3967L8.89333 27.3042L10.4419 28.3357C11.2962 28.9048 12.44 28.2923 12.44 27.2659C12.44 26.8647 12.2527 26.4865 11.9335 26.2433L9.69333 24.5367C9.46254 24.3614 9.18183 24.2667 8.89333 24.2667C8.60484 24.2667 8.32413 24.3614 8.09333 24.5367L6.66667 25.6167V5.36668H25.3333V10.6905C25.3333 11.4269 25.9303 12.0238 26.6667 12.0238C27.403 12.0238 28 11.4269 28 10.6905V4.01668C28 3.65864 27.8595 3.31526 27.6095 3.06209C27.3594 2.80891 27.0203 2.66668 26.6667 2.66668Z"
        fill="#BFBFBF"/>
      <rect x="13.3333" y="13.3333" width="18.6667" height="18.6667" rx="9.33333" fill="#1688AE"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.6888 17.0667C23.0754 17.0672 23.3884 17.381 23.3879 17.7676L23.3824 21.9667H27.5667C27.9532 21.9667 28.2667 22.2801 28.2667 22.6667C28.2667 23.0533 27.9532 23.3667 27.5667 23.3667H23.3806L23.375 27.5676C23.3745 27.9542 23.0607 28.2672 22.6741 28.2667C22.2875 28.2662 21.9745 27.9524 21.9751 27.5658L21.9806 23.3667H17.7667C17.3801 23.3667 17.0667 23.0533 17.0667 22.6667C17.0667 22.2801 17.3801 21.9667 17.7667 21.9667H21.9824L21.9879 17.7658C21.9884 17.3792 22.3022 17.0662 22.6888 17.0667Z"
            fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2976_18861">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default AddInvoiceIcon;
