import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {UnmountClosed} from 'react-collapse';
import {StyledTabHelper, StyledTabHelperDescription, StyledTabHelperTitle} from './StyledTabHelper';
import {AddInvoiceIcon} from '../../../../../icons';

const TabHelper = ({
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [isTabHelperVisible, setIsTabHelperVisible] = useState(true);

  const tabHelperT = (key) => t(`invoices:tabHelper.${key}`);

  const handleCloseHelper = () => setIsTabHelperVisible(false);

  return (
    <UnmountClosed isOpened={isTabHelperVisible}>
      <StyledTabHelper {...rest}>
        <StyledTabHelperTitle>
          {tabHelperT('title')}
        </StyledTabHelperTitle>
        <StyledTabHelperDescription>
          <span className='d-inline-flex'>{tabHelperT('description1')} <AddInvoiceIcon /></span> {tabHelperT('description2')}
          &nbsp;
          <Button
            onClick={handleCloseHelper}
            type='link'
          >
            {t('hide')}
          </Button>
        </StyledTabHelperDescription>
      </StyledTabHelper>
    </UnmountClosed>
  );
}

export default TabHelper;
