import { companyConstants } from '../constants';
import {localizationHelpers} from '../../utils/helpers';

const defaultTeamEmployees = {
  searchQuery: {},
  pagination: {
    next_page: null,
    cursor: null,
  },
  users: []
}

const companyState = {
  id: null,
  tags: [],
  employees: [],
  logo: null,
  name: null,
  country: null,
  address: null,
  vat: null,
  currentCurrency: null,
  teamEmployees: defaultTeamEmployees,
  employee: {},
  settings: {},
  full_address: null,
  zip_code: null,
  zip_city: null
};


const companyReducer = (state = companyState, action) => {
  const {type, data} = action;
  switch (type) {
    case companyConstants.GET_TAGS:
    case companyConstants.DELETE_TAG:
      return {
        ...state,
        tags: data
      };
    case companyConstants.GET_EMPLOYEE:
      return {
        ...state,
        employee: data
      };
    case companyConstants.GET_EMPLOYEES:
      return {
        ...state,
        employees: data
      };
    case companyConstants.GET_TEAM_EMPLOYEES:
    case companyConstants.DELETE_EMPLOYEE:
    case companyConstants.RESEND_INVITE:
      return {
        ...state,
        teamEmployees: data
      };
    case companyConstants.CLEAR_TEAM_EMPLOYEES:
      return {
        ...state,
        teamEmployees: defaultTeamEmployees
      }
    case companyConstants.SET_COMPANY:
      return {
        ...state,
        country: localizationHelpers.getCountryCodeByName(data.country),
        name: data.name || null,
        id: data.company_id || null,
        logo: data.logo_url || null,
        settings: {}
      };
    case companyConstants.UPDATE_EMPLOYEE:
    case companyConstants.UPDATE_EMPLOYEE_STATUS:
      return {
        ...state,
        teamEmployees: action.teamEmployees,
        employee: data
      };
    case companyConstants.GET_SETTINGS:
      return {
        ...state,
        settings: data
      }
    case companyConstants.GET_COMPANY_INVOICE_EMAIL:
      return {
        ...state,
        settings: {...state.settings, ...data}
      }
    case companyConstants.CLEAR_COMPANY_DATA:
      return {
        ...companyState
      }
    case companyConstants.GET_COMPANY_INFORMATION:
      return {
        ...state,
        ...data
      }
    case companyConstants.GET_COMPANY_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: data
      }
    default:
      return state
  }
};

export default companyReducer;
