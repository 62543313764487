export const transactionConstants = {
  CLEAR_TRANSACTIONS_BANKING_DETAILS: 'CLEAR_TRANSACTIONS_BANKING_DETAILS',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',
  GET_TRANSACTION_LIST: 'GET_TRANSACTION_LIST',
  SET_BALANCE_DETAILS: 'SET_BALANCE_DETAILS',
  SET_WIRE_DETAILS: 'SET_WIRE_DETAILS',
  SET_COMPANY_BANKING_USER_ID: 'SET_COMPANY_BANKING_USER_ID',

  CLOSE_ADD_INVOICE_MODAL: 'CLOSE_ADD_INVOICE_MODAL',
  OPEN_ADD_INVOICE_MODAL: 'OPEN_ADD_INVOICE_MODAL'
};
